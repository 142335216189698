import { differenceInYears } from 'date-fns'
import { useMemo } from 'react'
import { Registration } from '../../../contexts/RegistrationContext/RegistrationContext'
import { SeasonRegistrationType } from '../../../generated/graphql'
import useFeatureFlagOn from '../../../hooks/useFeatureFlagOn'

/**
 * @description Photo Verification is enabled for the following registration types
 *
 * see [GO-31063](https://gameonsport.atlassian.net/browse/GO-31063) for reference
 */
const ENABLED_SEASON_REGISTRATION_TYPES: SeasonRegistrationType[] = [
  SeasonRegistrationType.ParticipantToClub,
  SeasonRegistrationType.ParticipantToClubTeam,
  SeasonRegistrationType.ParticipantToTeam,
  SeasonRegistrationType.ParticipantToAssociation,
]

export type ProfilePhotoVerificationActiveProps = Pick<
  Registration,
  'registrationType' | 'dateOfBirth'
> & {
  seasonRegistrationType?: SeasonRegistrationType
}

/**
 *
 * @description determines whether Profile Photo Verification
 * is enabled for the current competition registration
 *
 * Step is displayed if:
 * - Registration role is of PLAYER type
 * - Profile/Dependant is aged 16 or over
 * - Registration is Participant to Season, Participant to Team, Participant to Club, or Participant to Club Team
 * - FF is enabled
 * - Tenant flag is enabled (TBC in [GO-31062](https://gameonsport.atlassian.net/browse/GO-31062))
 */
export const useProfilePhotoVerificationActive = ({
  registrationType,
  seasonRegistrationType,
  dateOfBirth,
}: ProfilePhotoVerificationActiveProps) => {
  const isFfEnabled = useFeatureFlagOn('layup-profile-photo-verification')

  const ageInYears = useMemo(() => {
    if (!dateOfBirth) return 0
    return differenceInYears(new Date(), new Date(dateOfBirth))
  }, [dateOfBirth])

  if (!isFfEnabled) {
    return false
  }

  if (
    !seasonRegistrationType ||
    !ENABLED_SEASON_REGISTRATION_TYPES.includes(seasonRegistrationType)
  ) {
    return false
  }

  if (registrationType !== 'PLAYER') {
    return false
  }

  if (ageInYears < 16) {
    return false
  }

  return true
}
