import { useMatch } from '@reach/router'

/**
 * @returns The tenant from the URL. (if it exists)
 */
export const useTenant = () => {
  const match = useMatch('/:tenant/*')

  return match?.tenant ?? null
}
