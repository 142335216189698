import React from 'react'
import { Tooltip } from 'react-tooltip'
import styled from 'styled-components'

const BaseStep = styled.li`
  border-radius: 100%;
  min-width: ${({ theme }) => theme.fontSizes[36]};
  min-height: ${({ theme }) => theme.fontSizes[36]};

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: ${({ theme }) => theme.fontSizes[16]};
  font-weight: 500;
`

const HiddenHelperText = styled.span`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 0.063rem;
  margin: -0.063rem;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 0.063rem;
  white-space: nowrap;
`

const PreviousStep = styled(BaseStep)`
  color: ${({ theme }) => theme.colors.black400};
  background-color: ${({ theme }) => theme.colors.lightGrey400};
`
const CurrentStep = styled(BaseStep)`
  color: ${({ theme }) => theme.colors.white400};
  background-color: ${({ theme }) => theme.colors.blueberry400};
`
const FutureStep = styled(BaseStep)`
  color: ${({ theme }) => theme.colors.darkGrey400};
  background-color: ${({ theme }) => theme.colors.white400};
`

const OrderedList = styled.ol`
  display: flex;
  gap: ${({ theme }) => theme.spacing.xs};
  list-style: none;
  padding: 0;
  margin: 0;
`

/**
 * @link https://www.figma.com/design/uRUGNipE7K2Wm08U636xsG/PlayHQ-Library---Components?node-id=43-80&t=GNNjuGND4ct8ev4y-1
 * @param {number} currentStep - The current step number zero indexed
 * @param {number} totalSteps - The total number of steps
 */
const StepsV2 = ({
  currentStep,
  steps,
}: {
  currentStep: number
  steps: Array<{ title?: string | null }>
}) => {
  return (
    <OrderedList>
      {steps.map((step, index) => {
        if (index === currentStep) {
          return (
            <>
              <CurrentStep
                key={'step-' + index}
                data-testid={'current-step-' + index}
                data-tooltip-id={'current-step-' + index}
                data-tooltip-content={step.title ?? `Step ${index + 1}`}
              >
                <HiddenHelperText>Current:</HiddenHelperText>
                {index + 1}
              </CurrentStep>
              <Tooltip place="top" id={'current-step-' + index} />
            </>
          )
        }
        if (index < currentStep) {
          return (
            <>
              <PreviousStep
                key={'step-' + index}
                data-testid={'previous-step-' + index}
                data-tooltip-id={'previous-step-' + index}
                data-tooltip-content={step.title ?? `Step ${index + 1}`}
              >
                <HiddenHelperText>Previous:</HiddenHelperText>
                {index + 1}
              </PreviousStep>
              <Tooltip place="top" id={'previous-step-' + index} />
            </>
          )
        }
        return (
          <>
            <FutureStep
              key={'step-' + index}
              data-testid={'future-step-' + index}
              data-tooltip-id={'future-step-' + index}
              data-tooltip-content={step.title ?? `Step ${index + 1}`}
            >
              <HiddenHelperText>Future:</HiddenHelperText>
              {index + 1}
            </FutureStep>
            <Tooltip place="top" id={'future-step-' + index} />
          </>
        )
      })}
    </OrderedList>
  )
}

export default StepsV2
