import Loader from '@gameonsports/components/cjs/Loader'
import Notification from '@gameonsports/components/cjs/Notification'
import { Location } from '@reach/router'
import React, { useContext } from 'react'
import styled from 'styled-components'
import ThemeContext from '../../contexts/ThemeContext/ThemeContext'
import {
  useOrganisationPageWrapperNewsQuery,
  useOrganisationPageWrapperQuery,
} from '../../generated/graphql'
import { setOrganisationContacts } from '../../utils/organisation'
import slugify from '../../utils/slugify'
import { media } from '../../utils/styled-components-utils'
import OrganisationFooter from '../OrganisationFooter'
import OrgLogoName from '../OrgLogoName'
import PageHeader from '../PageHeader'
import PageNavigation, { NavLink } from '../PageNavigation'

const StyledOrgLogoName = styled(OrgLogoName)`
  padding: 0;
`

const StyledH1 = styled.h1`
  margin: 0;
`

const Body = styled.div`
  padding-bottom: 2rem;

  ${media.tablet`
    padding-bottom: 3rem;
  `}
`

interface OrganisationContact {
  id: string
  firstName: string
  lastName: string
  position: string
  email?: string | null
  phone?: string | null
}

interface Organisation {
  id: string
  name: string
  email: string
  contactNumber: string
  websiteUrl?: string | null
  address?: {
    id: string
    line1: string
    suburb: string
    postcode: string
    state: string
    country: string
  } | null
  logo?: {
    sizes: Array<{
      url: string
      dimensions: {
        width: number
        height: number
      }
    }>
  } | null
  contacts: OrganisationContact[]
  shopVisible?: boolean | null
}

export type OrganisationPageWrapperProps = {
  organisation?: Organisation
  orgCode?: string
  hideTabs?: boolean
  tenant: string
}

export const OrganisationPageWrapper: React.FC<
  OrganisationPageWrapperProps
> = ({ children, hideTabs, tenant, ...props }) => {
  const { isWebview } = useContext(ThemeContext)
  const orgCode = props.orgCode || props.organisation?.id

  const { data, loading } = useOrganisationPageWrapperQuery({
    variables: { orgCode: String(orgCode) },
    skip: isWebview || !orgCode || !!props.organisation,
  })

  const organisation =
    props.organisation ||
    (data?.discoverOrganisation &&
      data.tenantConfiguration &&
      setOrganisationContacts(
        data.discoverOrganisation,
        data.tenantConfiguration.contactRoles,
      ))

  const { data: newsData } = useOrganisationPageWrapperNewsQuery({
    variables: { orgCode: String(orgCode) },
    skip: isWebview || !orgCode,
    fetchPolicy: 'cache-and-network',
  })

  if (isWebview) {
    return <>{children}</>
  }

  if (loading) {
    return <Loader />
  }

  if (!organisation) {
    return (
      <Notification variant="error">
        Unable to load organisation. Please try again.
      </Notification>
    )
  }

  return (
    <Location>
      {({ location }) => (
        <>
          <PageHeader size="small">
            <StyledH1>
              <StyledOrgLogoName
                size="xxlarge"
                name={organisation.name}
                light
                logo={organisation.logo || undefined}
                logoSize="huge"
                weight="bold"
                tenant={tenant}
                link={`/${tenant}/org/${slugify(
                  organisation.name,
                )}/${organisation.id.split('-', 1)}`}
              />
            </StyledH1>
          </PageHeader>
          {!hideTabs && (
            <PageNavigation>
              <NavLink
                to={`/${tenant}/org/${slugify(organisation.name)}/${
                  organisation.id
                }`}
                aria-current={
                  location &&
                  location.pathname.includes(slugify(organisation.name)) &&
                  !location.pathname.includes('/register') &&
                  !location.pathname.includes('/shop') &&
                  !location.pathname.includes('/news')
                    ? 'page'
                    : undefined
                }
                data-testid="fixtures-ladders-link"
              >
                Fixtures &amp; Ladders
              </NavLink>
              <NavLink
                to={`/${tenant}/org/${slugify(organisation.name)}/${
                  organisation.id
                }/register`}
                data-testid="register-link"
              >
                Register
              </NavLink>
              {organisation.shopVisible && (
                <NavLink
                  to={`/${tenant}/org/${slugify(organisation.name)}/${
                    organisation.id
                  }/shop`}
                  aria-current={
                    location &&
                    location.pathname.includes(slugify(organisation.name)) &&
                    location.pathname.includes('/shop')
                      ? 'page'
                      : undefined
                  }
                  data-testid="shop-link"
                >
                  Shop
                </NavLink>
              )}
              {!!newsData?.discoverOrganisationPosts?.meta?.totalRecords && (
                <NavLink
                  to={`/${tenant}/org/${slugify(organisation.name)}/${
                    organisation.id
                  }/news`}
                  aria-current={
                    location &&
                    location.pathname.includes(slugify(organisation.name)) &&
                    location.pathname.includes('/news')
                      ? 'page'
                      : undefined
                  }
                  data-testid="news-link"
                >
                  News
                </NavLink>
              )}
            </PageNavigation>
          )}
          <Body>{children}</Body>
          <OrganisationFooter {...organisation} />
        </>
      )}
    </Location>
  )
}

export default OrganisationPageWrapper
