import { Stack } from '@gameonsports/components/cjs/StackV2'
import { Text } from '@gameonsports/components/cjs/TextV3'
import React from 'react'
import { useRegistrationSteps } from '../contexts/RegistrationStepsContext'

export const RegistrationHeader = () => {
  const { currentStep } = useRegistrationSteps()

  const { title, description } = currentStep || {}

  if (!title && !description) {
    return null
  }

  return (
    <Stack gap="m">
      {title && (
        <Text weight="700" size="36">
          {title}
        </Text>
      )}
      {description && (
        <Text weight="400" size="16" lineHeight="1.5rem" color="darkGray">
          {description}
        </Text>
      )}
    </Stack>
  )
}
