import React, { useEffect, useRef } from 'react'
import { AnalyticsEvent } from '../../../analyticsEvents'
import { useAnalytics } from '../../../hooks/useAnalytics'
import { useRegistrationSteps } from '../contexts/RegistrationStepsContext'

interface StepTrackerProps {
  children: React.ReactNode
}

export const StepTracker: React.FC<StepTrackerProps> = ({ children }) => {
  const trackEvent = useAnalytics()
  const lastTrackedStepRef = useRef<string | null>(null)
  const debounceTimerRef = useRef<NodeJS.Timeout | null>(null)

  const { currentStep } = useRegistrationSteps()

  const stepEvents: Record<string, AnalyticsEvent> = {
    '1': 'reg:chooseParticipant_view',
    '2': 'reg:chooseRole_view',
    '3': 'reg:participantDetails_view',
    'claim-profiles': 'reg:claimProfiles_view',
    'photo-verification': 'reg:identityVerification_view',
    fees: 'reg:fees_view',
    products: 'reg:products_view',
    'external-id': 'reg:externalAccount_view',
    payment: 'reg:reviewConfirm_view',
  }

  // This is debouncing to prevent multiple event tracking when the page refreshes
  useEffect(() => {
    if (currentStep && currentStep.path) {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current)
      }

      debounceTimerRef.current = setTimeout(() => {
        if (lastTrackedStepRef.current !== currentStep.path) {
          trackEvent(stepEvents[currentStep.path])
          lastTrackedStepRef.current = currentStep.path
        }
      }, 300)
    }

    return () => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep])

  return <>{children}</>
}
