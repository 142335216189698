interface AnalyticsEventDetails {
  description: string
  squad: string
  ticketNo: string
  lastModified: string
  eventType?: string
}

// Allowed verbs: click, submit, create, view, add, invite, update, delete, remove, start, end, cancel, fail, generate
export type AnalyticsEvent =
  | 'orgpage:register_view'
  | 'orgpage:register_select_click'
  | 'reg:summary_view'
  | 'reg:summary_getStarted_click'
  | 'reg:chooseParticipant_view'
  | 'reg:chooseParticipant_continue_click'
  | 'reg:chooseRole_view'
  | 'reg:chooseRole_continue_click'
  | 'reg:participantDetails_view'
  | 'reg:participantDetails_continue_click'
  | 'reg:claimProfiles_view'
  | 'reg:claimProfiles_continue_click'
  | 'reg:identityVerification_view'
  | 'reg:identityVerification_continue_click'
  | 'reg:requestToPlay_view'
  | 'reg:requestToPlay_submit_view'
  | 'reg:fees_view'
  | 'reg:fees_continue_click'
  | 'reg:products_view'
  | 'reg:products_continue_click'
  | 'reg:externalAccount_view'
  | 'reg:externalAccount_continue_click'
  | 'reg:reviewConfirm_view'
  | 'reg:reviewConfirm_payAndConfirm_click'
  | 'reg:reviewConfirm_payment_submit'
  | 'reg:confirmation_view'

export const analyticsEvents: Record<AnalyticsEvent, AnalyticsEventDetails> = {
  'orgpage:register_view': {
    description: 'Loads Register tab in layup - org - register',
    squad: 'payments',
    ticketNo: 'GO-31522',
    lastModified: '05 Mar 2025',
  },
  'orgpage:register_select_click': {
    description:
      'Fires on click of "Select" of a particular registration form, which then opens a registration form',
    squad: 'payments',
    ticketNo: 'GO-31522',
    lastModified: '05 Mar 2025',
    eventType: 'Checkout Started',
  },
  'reg:summary_view': {
    description:
      'When participant lands on page Summary page with "Get Started"',
    squad: 'payments',
    ticketNo: 'GO-31522',
    lastModified: '05 Mar 2025',
    eventType: 'Checkout Step Viewed',
  },
  'reg:summary_getStarted_click': {
    description:
      'Triggered when a participant initiates the registration process on click "Get started"',
    squad: 'payments',
    ticketNo: 'GO-31522',
    lastModified: '05 Mar 2025',
    eventType: 'Checkout Step Completed',
  },
  'reg:chooseParticipant_view': {
    description: "Triggered when a participant lands on 'Choose Participant'",
    squad: 'payments',
    ticketNo: 'GO-31522',
    lastModified: '05 Mar 2025',
    eventType: 'Checkout Step Viewed',
  },
  'reg:chooseParticipant_continue_click': {
    description: 'Fires on successful click "Continue"',
    squad: 'payments',
    ticketNo: 'GO-31522',
    lastModified: '05 Mar 2025',
    eventType: 'Checkout Step Completed',
  },
  'reg:chooseRole_view': {
    description: 'Choose role page views',
    squad: 'payments',
    ticketNo: 'GO-31522',
    lastModified: '05 Mar 2025',
    eventType: 'Checkout Step Viewed',
  },
  'reg:chooseRole_continue_click': {
    description: 'Fires on successful click "Continue"',
    squad: 'payments',
    ticketNo: 'GO-31522',
    lastModified: '05 Mar 2025',
    eventType: 'Checkout Step Completed',
  },
  'reg:participantDetails_view': {
    description: 'Load page',
    squad: 'payments',
    ticketNo: 'GO-31522',
    lastModified: '05 Mar 2025',
    eventType: 'Checkout Step Viewed',
  },
  'reg:participantDetails_continue_click': {
    description: 'Fires on successful click "Continue"',
    squad: 'payments',
    ticketNo: 'GO-31522',
    lastModified: '05 Mar 2025',
    eventType: 'Checkout Step Completed',
  },
  'reg:claimProfiles_view': {
    description: 'Load page',
    squad: 'payments',
    ticketNo: 'GO-31522',
    lastModified: '05 Mar 2025',
    eventType: 'Checkout Step Viewed',
  },
  'reg:claimProfiles_continue_click': {
    description: 'Partcipant continues reg',
    squad: 'payments',
    ticketNo: 'GO-31522',
    lastModified: '05 Mar 2025',
    eventType: 'Checkout Step Completed',
  },
  'reg:identityVerification_view': {
    description: 'Load page',
    squad: 'payments',
    ticketNo: 'GO-31522',
    lastModified: '05 Mar 2025',
    eventType: 'Checkout Step Viewed',
  },
  'reg:identityVerification_continue_click': {
    description: 'Fires on successful click "Continue"',
    squad: 'payments',
    ticketNo: 'GO-31522',
    lastModified: '05 Mar 2025',
    eventType: 'Checkout Step Completed',
  },
  'reg:requestToPlay_view': {
    description: 'Page loads of transfer or permit options',
    squad: 'payments',
    ticketNo: 'GO-31522',
    lastModified: '05 Mar 2025',
    eventType: 'Checkout Step Viewed',
  },
  'reg:requestToPlay_submit_view': {
    description:
      'Participant registration has ended here and participant needs to wait',
    squad: 'payments',
    ticketNo: 'GO-31522',
    lastModified: '05 Mar 2025',
    eventType: 'Checkout Step Completed',
  },
  'reg:fees_view': {
    description: 'Fires on successful click "Continue"',
    squad: 'payments',
    ticketNo: 'GO-31522',
    lastModified: '05 Mar 2025',
    eventType: 'Checkout Step Viewed',
  },
  'reg:fees_continue_click': {
    description: 'Fires on successful click "Continue"',
    squad: 'payments',
    ticketNo: 'GO-31522',
    lastModified: '05 Mar 2025',
    eventType: 'Checkout Step Completed',
  },
  'reg:products_view': {
    description: 'Page load of products (if in)',
    squad: 'payments',
    ticketNo: 'GO-31522',
    lastModified: '05 Mar 2025',
    eventType: 'Checkout Step Viewed',
  },
  'reg:products_continue_click': {
    description: 'Fires on successful click "Continue"',
    squad: 'payments',
    ticketNo: 'GO-31522',
    lastModified: '05 Mar 2025',
    eventType: 'Checkout Step Completed',
  },
  'reg:externalAccount_view': {
    description: 'Participant is asked to link their external account',
    squad: 'payments',
    ticketNo: 'GO-31522',
    lastModified: '05 Mar 2025',
    eventType: 'Checkout Step Viewed',
  },
  'reg:externalAccount_continue_click': {
    description: 'Fires on successful click "Continue"',
    squad: 'payments',
    ticketNo: 'GO-31522',
    lastModified: '05 Mar 2025',
    eventType: 'Checkout Step Completed',
  },
  'reg:reviewConfirm_view': {
    description: 'Participant lands on review and confirm/payment screen',
    squad: 'payments',
    ticketNo: 'GO-31522',
    lastModified: '05 Mar 2025',
    eventType: 'Checkout Step Viewed',
  },
  'reg:reviewConfirm_payAndConfirm_click': {
    description: 'Fires on click "Submit registration"',
    squad: 'payments',
    ticketNo: 'GO-31522',
    lastModified: '05 Mar 2025',
    eventType: 'Checkout Step Completed',
  },
  'reg:reviewConfirm_payment_submit': {
    description: 'Fires on successful response from back-end',
    squad: 'payments',
    ticketNo: 'GO-31522',
    lastModified: '05 Mar 2025',
    eventType: 'Order Complete',
  },
  'reg:confirmation_view': {
    description: 'Confirmation page loads',
    squad: 'payments',
    ticketNo: 'GO-31522',
    lastModified: '05 Mar 2025',
  },
}
