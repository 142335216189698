import posthog from 'posthog-js'
import { useContext } from 'react'
import { AnalyticsEvent, analyticsEvents } from '../analyticsEvents'
import { publicEnv } from '../constants/publicEnv'
import AccountContext from '../contexts/AccountContext/AccountContext'
import { getEnvironment } from '../utils/env'
import useFeatureFlagOn from './useFeatureFlagOn'
import { useTenant } from './useTenant'

export const useAnalytics = () => {
  const { user } = useContext(AccountContext)

  // Currently the only tracking on layup is for the registration
  // flow so the feature flag is here to not litter the code with conditionals.
  // Eventually this will be removed when new events are added
  const isRegistrationTrackingOn = useFeatureFlagOn(
    'registration-user-path-tracking',
  )

  const tenant = useTenant()

  const environment = getEnvironment()

  return (eventName: AnalyticsEvent, properties = {}) => {
    if (isRegistrationTrackingOn) {
      posthog.capture(eventName, {
        profileId: user?.profile?.id,
        environment: environment ?? publicEnv.REACT_APP_ENVIRONMENT,
        tenant,
        eventType: analyticsEvents[`${eventName}`].eventType,
        ...properties,
      })
    }
  }
}
