import Icon from '@gameonsports/components/cjs/Icon'
import Steps from '@gameonsports/components/cjs/Steps'
import { Text } from '@gameonsports/components/cjs/TextV3'
import { MatchRenderProps, WindowLocation } from '@reach/router'
import startCase from 'lodash/startCase'
import React from 'react'
import styled from 'styled-components'
import SectionContainer from '../../../components/SectionContainer/SectionContainer'
import { useRegistrationContext } from '../../../contexts/RegistrationContext/RegistrationContext'
import {
  GetRegistrationRegistrationSettings,
  SeasonRegistrationUserType,
} from '../../../generated/graphql'
import { nonNullable } from '../../../utils/array'
import {
  getEventName,
  getExternalIDProviderName,
} from '../../../utils/registration'
import { media } from '../../../utils/styled-components-utils'

const BoxHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  ${media.tablet`
    margin-bottom: 2.5rem;
  `}

  > * + * {
    margin-top: 1.5rem;
  }
`

const CompSeasonName = styled.div`
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 0.5rem;
  }
`

interface MatchParams {
  type: string
}

type RegisteredTeam = {
  __typename?: 'DiscoverTeam'
  id: string
  name: string
}

export const RegistrationSteps: React.FC<{
  match: MatchRenderProps<MatchParams>['match']
  matchLocation: WindowLocation<unknown>
  registrationType: SeasonRegistrationUserType | null
  registrationSettings: GetRegistrationRegistrationSettings
  registeredTeam?: RegisteredTeam | null
  seasonOrganisationName: string | null
  tenant?: string
}> = ({
  match,
  matchLocation,
  registrationType,
  registrationSettings,
  registeredTeam,
  seasonOrganisationName,
  tenant,
}) => {
  const {
    hasDuplicateProfiles,
    isPhotoVerificationActive,
    hasExternalIdStep,
    hasExternalIdLinked,
  } = useRegistrationContext()

  const eventName = getEventName(registrationSettings.season)

  const { type } = match || { type: '' }

  let lastSegment = matchLocation.pathname.split('/').pop() || ''

  if (lastSegment === 'payment-callback') {
    lastSegment = 'payment'
  }

  const showClaimProfiles =
    !!hasDuplicateProfiles || lastSegment === 'claim-profiles'

  const hasProducts =
    'products' in registrationSettings &&
    registrationSettings.products &&
    registrationSettings.products.length > 0

  const paths =
    type === 'participant'
      ? [
          '1',
          '2',
          '3',
          showClaimProfiles ? 'claim-profiles' : null,
          isPhotoVerificationActive ? 'photo-verification' : null,
          'fees',
          hasProducts ? 'products' : null,
          hasExternalIdStep ? 'external-id' : null,
          'payment',
        ].filter(Boolean)
      : ['1', 'fees', hasProducts ? 'products' : null, 'payment'].filter(
          Boolean,
        )

  const currentStep = match
    ? paths.findIndex(p =>
        Array.isArray(p) ? p.indexOf(lastSegment) !== -1 : p === lastSegment,
      )
    : -1

  const steps =
    type === 'participant'
      ? [
          { title: 'Choose User' },
          {
            title: 'Choose Role',
            subtitle:
              registrationType && currentStep > 1
                ? `(${startCase(registrationType.toLowerCase())})`
                : undefined,
          },
          { title: 'Participant Details' },
          showClaimProfiles ? { title: 'Claim Profiles' } : null,
          isPhotoVerificationActive ? { title: 'Photo Verification' } : null,
          { title: 'Fees' },
          hasProducts ? { title: 'Products' } : null,
          hasExternalIdStep
            ? {
                title: getExternalIDProviderName(tenant),
                subtitle: hasExternalIdLinked ? '(Linked)' : undefined,
              }
            : null,
          { title: 'Payment & Summary' },
        ].filter(nonNullable)
      : [
          { title: 'Team Details' },
          { title: 'Fees' },
          hasProducts ? { title: 'Products' } : null,
          { title: 'Payment & Summary' },
        ].filter(nonNullable)

  return currentStep !== -1 ? (
    <SectionContainer noYPadding>
      <BoxHeader>
        <CompSeasonName>
          <Icon name="shield-swoosh" color="darkGrey400" />
          <Text weight="600" color="darkGrey400">
            {registeredTeam && `${registeredTeam.name}, `}
            {eventName}, {registrationSettings.season.name}
            {seasonOrganisationName && `, ${seasonOrganisationName}`}
          </Text>
        </CompSeasonName>
        <Steps steps={steps} currentStep={currentStep} />
      </BoxHeader>
    </SectionContainer>
  ) : null
}
