import { mq } from '@gameonsports/components/cjs/_utils/styled-components-utils'
import Button from '@gameonsports/components/cjs/Button'
import { Stack } from '@gameonsports/components/cjs/StackV2'
import React, { PropsWithChildren, useContext, useEffect } from 'react'
import styled from 'styled-components'
import BoxContainer from '../../../components/BoxContainer/BoxContainer'
import {
  OrganisationPageWrapper,
  OrganisationPageWrapperProps,
} from '../../../components/OrganisationPageWrapper/OrganisationPageWrapper'
import { Link } from '../../../components/Router'
import ThemeContext from '../../../contexts/ThemeContext/ThemeContext'
import useFeatureFlagOn from '../../../hooks/useFeatureFlagOn'
import slugify from '../../../utils/slugify'
import { media } from '../../../utils/styled-components-utils'
import FourOhFour from '../../404/404'
import { Container } from '../../RegistrationExternalID/RegistrationExternalID.styles'
import { useRegistrationSteps } from '../contexts/RegistrationStepsContext'
import { NAVIGATION_CONTAINER_HEIGHT } from './RegistrationNavigation'

const HEADER_HEIGHT = '5rem'

export const RegistrationPageWrapper = ({
  children,
  header,
  footer,
  ...props
}: PropsWithChildren<
  {
    header?: React.ReactNode
    footer?: React.ReactNode
  } & OrganisationPageWrapperProps
>) => {
  const isFlagOn = useFeatureFlagOn('registration-ui-enhancements')

  const { currentStepIndex, isSuccessStep } = useRegistrationSteps()

  const { isWebview, setHideHeader, setHideFooter, setHideFooterBanner } =
    useContext(ThemeContext)

  useEffect(
    function hideHeaderAndFooter() {
      if (currentStepIndex === -1 || !isFlagOn) {
        setHideHeader(false)
        setHideFooter(false)
        setHideFooterBanner(false)
        return
      }

      setHideHeader(true)
      setHideFooter(true)
      setHideFooterBanner(true)
      return () => {
        setHideHeader(false)
        setHideFooter(false)
        setHideFooterBanner(false)
      }
    },
    [
      setHideHeader,
      setHideFooter,
      setHideFooterBanner,
      currentStepIndex,
      isFlagOn,
    ],
  )

  if (isSuccessStep) {
    return (
      <OrganisationPageWrapper {...props}>
        <Stack>
          <RegistrationContainer>
            <PageContainer noPadding>
              <Stack>{children}</Stack>
            </PageContainer>
          </RegistrationContainer>
          {footer}
        </Stack>
      </OrganisationPageWrapper>
    )
  }

  if (currentStepIndex === -1) {
    return (
      <OrganisationPageWrapper {...props}>
        <Stack paddingX="s">
          <RegistrationContainer>
            <StyledBox>
              <Stack paddingX="xxl">{children}</Stack>
            </StyledBox>
          </RegistrationContainer>
          {footer}
        </Stack>
      </OrganisationPageWrapper>
    )
  }

  if (!isFlagOn) {
    return (
      <OrganisationPageWrapper {...props} hideTabs>
        <Stack paddingX="s">
          <RegistrationContainer>
            <StyledBox>{children}</StyledBox>
          </RegistrationContainer>
          {footer}
        </Stack>
      </OrganisationPageWrapper>
    )
  }

  const { organisation, tenant } = props
  if (!organisation?.name || !organisation?.id) {
    return <FourOhFour />
  }

  return (
    <StackNoPaddingMobile
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh', overflow: 'auto' }}
    >
      <Stack
        backgroundColor="white400"
        padding="none"
        style={{
          width: '100%',
          maxWidth: '60rem',
          flexGrow: 1,
          boxShadow: '0 2rem 4rem 1000rem rgba(0, 0, 0, 0.5)',
        }}
        gap="xl"
      >
        <FixedTopStack justifyContent="center" alignItems="center">
          <HeaderStack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            wrap="wrap"
            style={{
              width: '100%',
              maxWidth: '60rem',
              flexGrow: 1,
              height: HEADER_HEIGHT,
            }}
            backgroundColor="white400"
          >
            {!isWebview && (
              <div>
                <NudeButton
                  as={Link}
                  to={`/${tenant}/org/${slugify(
                    organisation.name,
                  )}/${organisation.id.split('-', 1)}/register`}
                >
                  Cancel
                </NudeButton>
              </div>
            )}
            {header && <Stack>{header}</Stack>}
          </HeaderStack>
        </FixedTopStack>
        <Stack style={{ height: HEADER_HEIGHT }} />
        <ChildrenStack>{children}</ChildrenStack>
        <Stack style={{ height: NAVIGATION_CONTAINER_HEIGHT }} />
      </Stack>
      {footer && <Stack>{footer}</Stack>}
    </StackNoPaddingMobile>
  )
}

const PageContainer = styled(BoxContainer).attrs({ as: 'div' })`
  max-width: 60rem;
  width: 100%;
`

const NudeButton = styled(Button)`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.blueberry400};
  font-weight: 600;
  text-decoration: none;
`

const FixedTopStack = styled(Stack)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
`

const HeaderStack = styled(Stack)`
  padding: ${({ theme }) => theme.spacing.l} ${({ theme }) => theme.spacing.xl};

  ${mq.down('mobile')} {
    padding: ${({ theme }) => theme.spacing.m} ${({ theme }) => theme.spacing.m};
  }
`

const ChildrenStack = styled(Stack)`
  padding: 0 ${({ theme }) => theme.spacing.xl};

  ${mq.down('mobile')} {
    padding: 0 ${({ theme }) => theme.spacing.m};
  }
`

const StackNoPaddingMobile = styled(Stack)`
  ${mq.down('mobile')} {
    padding: 0;
  }
`

/**
 * @deprecated remove after FF is deleted
 */
const RegistrationContainer = styled(Container)`
  flex-direction: column;
  align-items: center;
  margin: 0;

  ${mq.up('tablet')} {
    padding: 1.5rem;
  }
`

/**
 * @deprecated remove after FF is deleted
 */
const StyledBox = styled(BoxContainer).attrs({ as: 'div' })`
  max-width: 60rem;
  width: 100%;

  ${media.tablet`
    padding: 2.5rem 0;
  `}
`
