import { Attributes } from '@splitsoftware/splitio/types/splitio'
import React from 'react'
import { FeatureFlag as FeatureFlagName } from '../../flags'
import useFeatureFlag from '../../hooks/useFeatureFlag'

interface FeatureFlagProps {
  attributes?: Attributes
  name: FeatureFlagName
  off?: React.ReactNode
}

const FeatureFlag: React.FC<FeatureFlagProps> = ({
  attributes,
  name,
  children,
  off,
}) => {
  const [treatment] = useFeatureFlag(name, attributes)
  if (treatment === 'on') {
    return <>{children}</>
  }

  if (off) {
    return <>{off}</>
  }

  return null
}

export default FeatureFlag
