import { mq } from '@gameonsports/components/cjs/_utils/styled-components-utils'
import Notification from '@gameonsports/components/cjs/Notification'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  > * {
    flex-basis: 100%;
  }
  > * + * {
    margin-left: 0;
    margin-top: 2rem;
  }
  margin: 2rem 0;

  ${mq.up('mobile')} {
    flex-direction: row;
    > * + * {
      margin-left: 2rem;
      margin-top: 0;
    }
  }
`

export const HeroImageContainer = styled.figure`
  margin: 0;
  img {
    object-fit: contain;
    object-position: top;
    max-width: 100%;
    max-height: 100%;
  }
`

export const LinkingImageContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  > * + * {
    margin-left: 0.5rem;
  }
  img {
    max-width: 3.5rem;
    max-height: 3.5rem;
  }
`

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  height: 3.5rem;
  width: 3.5rem;
  padding: 0.25rem;
  background-color: ${props => props.theme.blackberry400};
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  > * + * {
    margin-top: 1rem;
  }
`

export const LinkingContainer = styled.div`
  margin-top: 1.5rem;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  padding: 0 0 1rem;
  & > * + * {
    margin-top: 1rem;
  }

  ${mq.up('tablet')} {
    padding: 0 2rem 0;
  }
`

export const UL = styled.ul`
  margin: 0;
  margin-bottom: 1rem;
`

export const ErrorNotification = styled(Notification).attrs({
  variant: 'error',
})`
  margin-top: 1rem;
`
