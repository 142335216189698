import Icon from '@gameonsports/components/cjs/Icon'
import Pill from '@gameonsports/components/cjs/Pill'
import { Text, TextProps, TextSize } from '@gameonsports/components/cjs/TextV3'
import React from 'react'
import styled from 'styled-components'
import { Link } from '../Router'

interface ImageSource {
  url: string
  dimensions: {
    width: number
    height?: number | null
  }
}
interface Image {
  sizes: ImageSource[]
}

export enum Size {
  huge = '4.5rem',
  xxxlarge = '4rem',
  xxlarge = '2.5rem',
  xlarge = '2.5rem',
  large = '2.5rem',
  normal = '2rem',
  small = '1.5rem',
  xsmall = '1rem',
}

export interface OrgLogoNameProps {
  name: string
  className?: string
  logo?: Image | null
  logoSize?: keyof typeof Size
  size?: keyof typeof Size
  light?: boolean
  tenant?: string
  link?: string
  [key: string]: any
}

const size: { [x: string]: TextSize } = {
  huge: '50',
  xxxlarge: '32',
  xxlarge: '28',
  xlarge: '22',
  large: '20',
  normal: '16',
  small: '14',
  xsmall: '12',
}

const OrganisationPair = styled.div<
  Pick<OrgLogoNameProps, 'logoSize' | 'light'>
>`
  display: flex;
  justify-items: center;
  align-items: center;
  text-decoration: none;
  padding: 0.75rem;
  color: ${props =>
    props.light ? props.theme.white400 : props.theme.black400};

  img,
  svg {
    width: ${props => (props.logoSize ? Size[props.logoSize] : Size.normal)};
    height: ${props => (props.logoSize ? Size[props.logoSize] : Size.normal)};
    margin-right: 1rem;
    flex-shrink: 0;
  }

  img {
    border-radius: 50%;
  }
`

const OrgName = styled(Text)`
  line-height: 1.125;
`

const NameHolder = styled.div`
  display: flex;
  flex-direction: column;

  > * + * {
    margin-top: 0.5rem;
  }
`

const StyledPill = styled(Pill)`
  border-radius: 1rem;
  align-self: flex-start;
  margin-bottom: 0.75rem;
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

const PillLink = styled(Link)`
  text-decoration: none;
  display: inline-flex;
  align-self: flex-start;
`

const OrgLogoName: React.FC<
  OrgLogoNameProps & Pick<TextProps, 'weight'>
> = props => {
  /*
    Dynamic sizing
    normal = parseInt(2rem, 10) * 16 * 2 = 64px
    xlarge = parseInt(3rem, 10) * 16 * 2 = 96px
  */
  const logo = props.logo
    ? props.logo.sizes.find(
        l =>
          l.dimensions.width ===
          parseInt(props.logoSize ? Size[props.logoSize] : Size.normal, 10) *
            16 *
            2,
      ) || props.logo.sizes.find(l => l.dimensions.width === 64 * 2)
    : undefined

  return (
    <OrganisationPair {...props}>
      {logo ? (
        <img src={logo.url} alt={props.name} />
      ) : (
        <Icon
          name="world"
          size="32"
          color={props.light ? 'white400' : undefined}
        />
      )}
      <NameHolder>
        {props.link ? (
          <StyledLink to={props.link}>
            <OrgName
              as="span"
              size={size[props.size || 'normal']}
              weight={props.weight}
              color={props.light ? 'white400' : 'black400'}
              className="organisation-name"
            >
              {props.name}
            </OrgName>
          </StyledLink>
        ) : (
          <OrgName
            as="span"
            size={size[props.size || 'normal']}
            weight={props.weight}
            color={props.light ? 'white400' : 'black400'}
            className="organisation-name"
          >
            {props.name}
          </OrgName>
        )}
        {props.tenant && (
          <PillLink to={`/${props.tenant}`}>
            <StyledPill
              text={props.tenant.replace(/-/g, ' ')}
              variant="tenant"
            />
          </PillLink>
        )}
      </NameHolder>
    </OrganisationPair>
  )
}

export default OrgLogoName
