import { mq } from '@gameonsports/components/cjs/_utils/styled-components-utils'
import Button from '@gameonsports/components/cjs/Button'
import { Stack } from '@gameonsports/components/cjs/StackV2'
import React from 'react'
import styled from 'styled-components'

export const NAVIGATION_CONTAINER_HEIGHT = '6rem'

export type RegistrationNavigationProps = {
  onBack: React.MouseEventHandler<HTMLButtonElement> &
    React.MouseEventHandler<HTMLAnchorElement>
  backDisabled?: boolean
  hideBack?: boolean

  onNext: React.MouseEventHandler<HTMLButtonElement> &
    React.MouseEventHandler<HTMLAnchorElement>
  nextDisabled?: boolean
  nextLoading?: boolean
  nextLabel?: string
  nextTestId?: string
  hideNextIcon?: boolean
}

export const RegistrationNavigation = ({
  onBack,
  backDisabled,
  hideBack,

  onNext,
  nextDisabled,
  nextLoading,
  nextLabel = 'Continue',
  nextTestId = 'continue-button',
  hideNextIcon,
}: RegistrationNavigationProps) => (
  <FixedBottomStack
    direction="row"
    gap="s"
    alignItems="center"
    justifyContent="center"
  >
    <GreyTopBorderStack
      direction="row"
      gap="s"
      alignItems="center"
      justifyContent="space-between"
      paddingX="m"
      paddingY="l"
      backgroundColor="white400"
      style={{
        width: '100%',
        maxWidth: '60rem',
        height: NAVIGATION_CONTAINER_HEIGHT,
        flexGrow: 1,
      }}
    >
      {!hideBack && (
        <NoHoverButton
          variant="white"
          style={{
            borderColor: 'red',
            padding: '1rem 0',
            border: 'none',
            borderRadius: 0,
          }}
          data-testid="cancel-button"
          onClick={onBack}
          disabled={backDisabled}
          icon="left-arrow-strong"
          iconPosition="left"
          color="blueberry400"
        >
          Back
        </NoHoverButton>
      )}
      {hideBack && <div />}
      <Button
        variant="primary"
        data-testid={nextTestId}
        style={{ width: '50%', height: '3rem' }}
        onClick={onNext}
        disabled={nextDisabled}
        loading={nextLoading}
        icon={hideNextIcon ? undefined : 'right-arrow-strong'}
        iconPosition={hideNextIcon ? undefined : 'right'}
      >
        {nextLabel}
      </Button>
    </GreyTopBorderStack>
  </FixedBottomStack>
)

const NoHoverButton = styled(Button)`
  &:hover {
    background-color: transparent;
    border-color: transparent;
  }
`

const FixedBottomStack = styled(Stack)`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2; /* Using a minimal effective value that's still higher than standard content */
`

const GreyTopBorderStack = styled(Stack)`
  border-top: 1px solid ${({ theme }) => theme.colors.grey400};

  padding: ${({ theme }) => theme.spacing.l} ${({ theme }) => theme.spacing.xl};

  ${mq.down('mobile')} {
    padding: ${({ theme }) => theme.spacing.m} ${({ theme }) => theme.spacing.m};
  }
`
