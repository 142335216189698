import { useLocation, useMatch } from '@reach/router'
import React, { createContext, useContext, useMemo } from 'react'
import { useRegistrationContext } from '../../../contexts/RegistrationContext/RegistrationContext'
import { useTenant } from '../../../hooks/useTenant'
import { getExternalIDProviderName } from '../../../utils/registration'

type RegistrationStepsContextProps = {
  currentStepIndex: number
  currentStep?: RegistrationStep
  steps: RegistrationStep[]
  isSuccessStep?: boolean
}

export const RegistrationStepsContext =
  createContext<RegistrationStepsContextProps>({
    currentStepIndex: 0,
    steps: [],
  })

export const registrationPaths = [
  '1',
  '2',
  '3',
  'claim-profiles',
  'photo-verification',
  'fees',
  'products',
  'external-id',
  'payment',
] as const

export type RegistrationPath = typeof registrationPaths[number]

export type RegistrationStep = {
  path: RegistrationPath
  title: string | null
  description: string | null
  visible: boolean
}

export const RegistrationStepsProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const match = useMatch('/:tenant/register/:registrationCode/:type/*')
  const location = useLocation()
  const tenant = useTenant()

  const {
    hasDuplicateProfiles,
    registrationSettings,
    isPhotoVerificationActive,
    hasExternalIdStep,
  } = useRegistrationContext()

  const { type } = match || { type: '' }

  let pathLastSegment = location.pathname.split('/').pop() || ''
  if (pathLastSegment === 'payment-callback') {
    pathLastSegment = 'payment'
  }

  const steps = useMemo(() => {
    const hasProducts =
      registrationSettings?.products && registrationSettings.products.length > 0

    const participantSteps: RegistrationStep[] = [
      {
        path: '1',
        title: 'Participant',
        description:
          'Select the person you want to register from the profiles in your account, or create a new dependant profile.',
        visible: true,
      },
      {
        path: '2',
        title: 'Role',
        description: 'Now, choose how you would like to participate.',
        visible: true,
      },
      {
        path: '3',
        title: 'Details',
        description:
          'Make sure the participant’s details are entered correctly. You will not be able to edit the first name and last name once you proceed.',
        visible: true,
      },
      {
        path: 'claim-profiles',
        title: 'Claim Profiles',
        description: null,
        visible: hasDuplicateProfiles || pathLastSegment === 'claim-profiles',
      },
      {
        path: 'photo-verification',
        title: 'Photo Verification',
        description: null,
        visible: isPhotoVerificationActive ?? false,
      },
      {
        path: 'fees',
        title: 'Fees',
        description: null,
        visible: true,
      },
      {
        path: 'products',
        title: 'Products',
        description: null,
        visible: hasProducts ?? false,
      },
      {
        path: 'external-id',
        title: getExternalIDProviderName(tenant ?? undefined),
        description: null,
        visible: hasExternalIdStep ?? false,
      },
      {
        path: 'payment',
        title: 'Review & Confirm',
        description: null,
        visible: true,
      },
    ]

    const teamSteps: RegistrationStep[] = [
      {
        path: '1',
        title: 'Register a team',
        description: null,
        visible: true,
      },
      {
        path: 'fees',
        title: 'Fees',
        description: null,
        visible: true,
      },
      {
        path: 'products',
        title: 'Products',
        description: null,
        visible: hasProducts ?? false,
      },
      {
        path: 'payment',
        title: 'Review & Confirm',
        description: null,
        visible: true,
      },
    ]

    return (type === 'participant' ? participantSteps : teamSteps).filter(
      s => s.visible,
    )
  }, [
    hasDuplicateProfiles,
    hasExternalIdStep,
    isPhotoVerificationActive,
    pathLastSegment,
    registrationSettings?.products,
    tenant,
    type,
  ])

  const currentStepIndex = match
    ? steps.findIndex(({ path }) => path === pathLastSegment)
    : -1

  const currentStep = useMemo(
    () => steps[currentStepIndex],
    [currentStepIndex, steps],
  )

  return (
    <RegistrationStepsContext.Provider
      value={{
        currentStep,
        currentStepIndex,
        steps,
        isSuccessStep: pathLastSegment === 'success',
      }}
    >
      {children}
    </RegistrationStepsContext.Provider>
  )
}

export const useRegistrationSteps = () => useContext(RegistrationStepsContext)
