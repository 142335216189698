import React from 'react'
import styled from 'styled-components'
import { Link } from '../Router'

const Nav = styled.nav`
  width: 100%;
  overflow: auto;
  padding: 0 1rem;
`

const NavList = styled.ul`
  margin: 0 auto;
  padding: 0;
  list-style-type: none;
  display: flex;
  max-width: 60rem;
  width: 100%;
  white-space: nowrap;
  overflow: scroll;

  > * + * {
    margin-left: 2rem;
  }

  @supports (display: grid) {
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    justify-items: start;
    grid-gap: 0.5rem 2rem;

    > * + * {
      margin-left: initial;
    }
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const NavLink = styled(Link)`
  font-size: 1.125rem;
  color: ${props => props.theme.white400};
  text-decoration: none;
  transition: opacity 175ms ${props => props.theme.easeInOutCirc};
  padding: 0.25rem 0;
  font-weight: 500;
  opacity: 0.8;

  &::after {
    content: '';
    display: block;
    pointer-events: none;
    width: 100%;
    height: 0.25rem;
    border-radius: 0.25rem;
    background-color: ${props => props.theme.blueberry400};
    opacity: 0;
    transform: scaleX(0);
    transform-origin: 0 0;
    transition: opacity 175ms ${props => props.theme.easeInOutCirc},
      transform 175ms ${props => props.theme.easeInOutCirc};

    /* Disabling stylelint since it's just a 1px margin */
    /* stylelint-disable-next-line declaration-property-unit-allowed-list */
    margin-top: 10px;
  }

  &:hover,
  &[aria-current] {
    opacity: 1;

    &::after {
      opacity: 1;
      transform: scaleX(1);
    }
  }
`

const PageNavigation: React.FC<{ className?: string }> = ({
  className,
  children,
}) => (
  <Nav className={className}>
    <NavList>
      {React.Children.map(children, child => child && <li>{child}</li>)}
    </NavList>
  </Nav>
)

export default PageNavigation
