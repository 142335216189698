import Button from '@gameonsports/components/cjs/Button'
import Icon from '@gameonsports/components/cjs/Icon'
import Loader from '@gameonsports/components/cjs/Loader'
import { Stack } from '@gameonsports/components/cjs/Stack'
import { Text } from '@gameonsports/components/cjs/TextV3'
import Component from '@reach/component-component'
import { Match, Redirect, RouteComponentProps } from '@reach/router'
import { zonedTimeToUtc } from 'date-fns-tz'
import dateIsAfter from 'date-fns/isAfter'
import dateIsBefore from 'date-fns/isBefore'
import React, { useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { StripeProvider } from 'react-stripe-elements'
import styled from 'styled-components'
import BoxContainer from '../../components/BoxContainer'
import FeatureFlag from '../../components/FeatureFlag/FeatureFlag'
import FeedbackAndSupport from '../../components/FeedbackAndSupport'
import OrganisationPageWrapper from '../../components/OrganisationPageWrapper'
import RegistrationUnavailable from '../../components/RegistrationUnavailable'
import { Link, prependBasePathToUrl } from '../../components/Router'
import SectionContainer from '../../components/SectionContainer'
import { publicEnv } from '../../constants/publicEnv'
import AccountContext from '../../contexts/AccountContext'
import * as RegistrationContext from '../../contexts/RegistrationContext'
import ThemeContext from '../../contexts/ThemeContext/ThemeContext'
import {
  GetRegistrationRegistrationSettings,
  GetRegistration_Organisation,
  SeasonRegistrationType,
  SeasonRegistrationUserType,
  useGetLinkedExternalAccountsQuery,
  useGetRegistrationQuery,
  useTeamQuery,
} from '../../generated/graphql'
import useFeatureFlagOn from '../../hooks/useFeatureFlagOn'
import useScript from '../../hooks/useScript'
import { getLocationParams } from '../../utils/location'
import { setOrganisationContacts } from '../../utils/organisation'
import {
  getEventName,
  getEventOrganisationName,
  getOrganisationName,
} from '../../utils/registration'
import { media } from '../../utils/styled-components-utils'
import { aflTracking } from './aflTracking'
import { RegistrationHeader } from './components/RegistrationHeader'
import { RegistrationPageWrapper } from './components/RegistrationPageWrapper'
import { RegistrationProgress } from './components/RegistrationProgress'
import { RegistrationSteps } from './components/RegistrationSteps'
import { StepTracker } from './components/StepTracker'
import { RegistrationStepsProvider } from './contexts/RegistrationStepsContext'

const Container = styled.div`
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  ${media.tablet`
    padding: 4.5rem 2rem;
  `}
`

const RegistrationContainer = styled(Container)`
  padding: 1rem;

  ${media.tablet`
    padding: 1.5rem;
  `}
`

const StyledBox = styled(BoxContainer).attrs({ as: 'div' })`
  max-width: 60rem;
  width: 100%;
  padding: 1rem 0;

  ${media.tablet`
    padding: 2.5rem 0;
  `}
`

const ErrorContainer = styled.div`
  display: grid;
  grid-gap: 1.5rem;
  text-align: center;
  padding: 5rem 3.75rem;
`

const StyledFeedback = styled(FeedbackAndSupport)`
  margin-top: 4rem;
`

const getOrganisation = (
  registrationSettings: GetRegistrationRegistrationSettings,
) => {
  if (registrationSettings.__typename === 'DiscoverRegistration') {
    return registrationSettings.organisation
  }
  if (registrationSettings.season.__typename === 'DiscoverSeason') {
    return registrationSettings.season.competition.organisation
  }
  return
}

const getSeasonOrganisationName = (
  registrationSettings: GetRegistrationRegistrationSettings,
  organisation?: GetRegistration_Organisation,
) => {
  if (registrationSettings.season.__typename === 'ProgramSeason') {
    return organisation?.name || null
  }

  if (
    registrationSettings.__typename === 'DiscoverRegistration' &&
    (registrationSettings.type === SeasonRegistrationType.ParticipantToClub ||
      registrationSettings.type ===
        SeasonRegistrationType.ParticipantToClubTeam)
  ) {
    return getEventOrganisationName(registrationSettings.season)
  }

  return null
}

interface RegistrationRouteParams {
  registrationCode: string
  tenant: string
}

const Registration: React.FC<RouteComponentProps<RegistrationRouteParams>> = ({
  registrationCode,
  location,
  children,
  tenant,
}) => {
  const params = location ? getLocationParams(location) : {}
  const [regCode, ...teamIdSections] = String(registrationCode).split('-')
  const teamId = teamIdSections.join('-')
  const caAccountLinkingOn = useFeatureFlagOn('ca-account-linking')
  const aflAccountLinkingOn = useFeatureFlagOn('layup-afl-account-linking')
  const registrationKillSwitchOn = useFeatureFlagOn(
    'layup-registration-kill-switch',
  )
  const { user } = useContext(AccountContext)
  const { isWebview } = useContext(ThemeContext)

  useEffect(() => {
    if (tenant === 'afl') aflTracking(params)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    data: teamData,
    loading: teamLoading,
    error: teamError,
  } = useTeamQuery({
    variables: {
      teamId,
    },
    skip: !teamId,
    onError: () => null,
  })

  const { data, loading, error, refetch } = useGetRegistrationQuery({
    variables: {
      code: String(regCode),
      skipTenantConfig: false,
    },
    onError: () => null,
  })

  const {
    data: profileData,
    loading: profileLoading,
    error: profileError,
  } = useGetLinkedExternalAccountsQuery({
    onError: () => null,
    skip: !user,
  })

  // Load Stripe
  const [scriptLoaded, scriptError] = useScript('https://js.stripe.com/v3/')

  // Redirect old team urls to new location
  if (teamIdSections.length > 1) {
    return (
      <Redirect
        to={prependBasePathToUrl(
          `/${tenant}/register/${regCode}-${teamIdSections[0]}`,
        )}
      />
    )
  }

  if (
    (!data?.registrationSettings && loading) ||
    teamLoading ||
    profileLoading ||
    !scriptLoaded
  ) {
    return <Loader />
  }

  if (
    error ||
    teamError ||
    profileError ||
    !data ||
    !data.registrationSettings ||
    scriptError
  ) {
    return (
      <Container>
        <StyledBox>
          <ErrorContainer>
            <Text weight="700" size="22">
              Registration form unavailable.
            </Text>
            <Text>We could not find this registration form.</Text>
          </ErrorContainer>
        </StyledBox>
      </Container>
    )
  }

  const { registrationSettings, tenantConfiguration } = data
  const { contactRoles, externalAccountLinking } = tenantConfiguration!
  const { season } = registrationSettings
  const eventName = getEventName(registrationSettings.season)

  const _organisation = getOrganisation(registrationSettings)
  const organisation =
    _organisation && setOrganisationContacts(_organisation, contactRoles)
  const seasonOrganisationName = getSeasonOrganisationName(
    registrationSettings,
    organisation,
  )
  const sportName = data.tenantConfiguration?.sport?.name

  const outOfBounds =
    dateIsBefore(
      new Date(),
      zonedTimeToUtc(
        `${registrationSettings.startDate.date}T${registrationSettings.startDate.time}`,
        registrationSettings.startDate.timezone,
      ),
    ) ||
    dateIsAfter(
      new Date(),
      zonedTimeToUtc(
        `${registrationSettings.endDate.date}T${registrationSettings.endDate.time}`,
        registrationSettings.endDate.timezone,
      ),
    )

  const registeredTeam = teamData && teamData.discoverTeam
  const externalAccounts = profileData?.account?.profile?.externalAccounts

  const hasProducts =
    'products' in registrationSettings &&
    registrationSettings.products &&
    registrationSettings.products.length > 0

  if (outOfBounds || !organisation) {
    return (
      <Container>
        <StyledBox>
          <SectionContainer>
            <RegistrationUnavailable
              associationName={getOrganisationName(registrationSettings)}
              competitionName={eventName}
              seasonName={season.name}
              teamName={registeredTeam ? registeredTeam.name : undefined}
            />
          </SectionContainer>
        </StyledBox>
      </Container>
    )
  }

  // When the killswitch is enabled, registration will *not* work and only
  // display this info page with a link back to the organisation page
  if (registrationKillSwitchOn) {
    return (
      <OrganisationPageWrapper
        organisation={organisation}
        hideTabs
        tenant={String(tenant)}
      >
        <RegistrationContainer>
          <StyledBox>
            <Helmet title="Registration" />
            <Stack
              alignItems="center"
              gap="xl"
              padding="xxl"
              textAlign="center"
            >
              <Icon name="info" size="40" color="darkGrey400" />
              <Text size="24" weight="600">
                Registrations are currently unavailable. Please try again later.
              </Text>
              <Button as={Link} to={`/${String(tenant)}`}>
                Go Back
              </Button>
            </Stack>
          </StyledBox>
        </RegistrationContainer>
      </OrganisationPageWrapper>
    )
  }

  return (
    <StripeProvider
      apiKey={
        data.tenantConfiguration?.stripePublicKey ??
        String(publicEnv.REACT_APP_STRIPE_KEY)
      }
    >
      <RegistrationContext.Provider
        registrationType={params.type as SeasonRegistrationUserType}
        registrationSettings={registrationSettings}
        isDeferredFeesEnabled={
          (registrationSettings?.__typename === 'DiscoverRegistration' &&
            registrationSettings.deferredFees) ??
          false
        }
        who={params.who}
        hasExternalIdStep={
          !isWebview &&
          ((caAccountLinkingOn && sportName === 'cricket') ||
            (aflAccountLinkingOn && sportName === 'afl')) &&
          externalAccountLinking?.enabled &&
          registrationSettings.__typename !==
            'DiscoverSocialTeamRegistration' &&
          externalAccounts?.filter(account => account.tenantSlug === tenant)
            .length === 0
        }
        hasProducts={hasProducts}
        deferredFeesDueDate={
          registrationSettings?.__typename === 'DiscoverRegistration' &&
          registrationSettings.deferredFeesDueDate
            ? registrationSettings.deferredFeesDueDate
            : null
        }
      >
        <RegistrationContext.Consumer>
          {({ registrationType }) => (
            <RegistrationStepsProvider>
              <RegistrationPageWrapper
                organisation={organisation}
                hideTabs
                tenant={String(tenant)}
                header={
                  <FeatureFlag name="registration-ui-enhancements">
                    <RegistrationProgress />
                  </FeatureFlag>
                }
                footer={
                  !isWebview && (
                    <Match path="/:tenant/register/:registrationCode">
                      {({ match }) =>
                        match &&
                        !match.uri.includes('external-account-callback') && (
                          <StyledFeedback />
                        )
                      }
                    </Match>
                  )
                }
              >
                <Component<{ registrationType?: SeasonRegistrationUserType }>
                  registrationType={registrationType}
                  didUpdate={({ prevProps, props }) => {
                    if (prevProps.registrationType !== props.registrationType) {
                      refetch({
                        code: String(regCode),
                        role: props.registrationType,
                      })
                    }
                  }}
                />

                <Helmet title="Registration" />

                <FeatureFlag
                  name="registration-ui-enhancements"
                  off={
                    <Match<{
                      type: string
                    }> path="/:tenant/register/:registrationCode/:type/*">
                      {({ match, location: matchLocation }) => (
                        <RegistrationSteps
                          match={match}
                          matchLocation={matchLocation}
                          registrationType={registrationType}
                          registrationSettings={registrationSettings}
                          registeredTeam={registeredTeam}
                          seasonOrganisationName={seasonOrganisationName}
                          tenant={tenant}
                        />
                      )}
                    </Match>
                  }
                />

                <Stack gap="l">
                  <FeatureFlag
                    name="registration-ui-enhancements"
                    off={
                      <SectionContainer noYPadding>{children}</SectionContainer>
                    }
                  >
                    <RegistrationHeader />
                    <StepTracker>{children}</StepTracker>
                  </FeatureFlag>
                </Stack>
              </RegistrationPageWrapper>
            </RegistrationStepsProvider>
          )}
        </RegistrationContext.Consumer>
      </RegistrationContext.Provider>
    </StripeProvider>
  )
}

export default Registration
